.hero-content {
  position: relative;
  display: block;
  opacity: 0;
  transform: perspective(400px) rotateY(0deg) translateY(-120px);
  transform-origin: top;
  transition: all 1000ms ease;
  z-index: 44;
}

.hero {
  :global(.swiper-slide-active) {
    .hero-content {
      opacity: 1;
      transform: perspective(400px) rotateY(0deg) translateY(0px);
      transition-delay: 700ms;
    }
    .hero-bg {
      transform: scale(1.05);
    }
  }
}
